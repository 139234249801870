import React, { FC, useEffect, useState } from "react";
import styles from "./CopyButton.module.scss";
import copy from "copy-to-clipboard";
import useNotifyStore from "store/NotifyStore";

interface Props {
  value: any;
  children?: React.ReactNode;
  message: string;
}

export const CopyButton: FC<Props> = ({ value, children, message }) => {
  const [showCheck, setShowCheck] = useState(false);
  const { setCopied } = useNotifyStore();

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();

    copy(value);
    setShowCheck(true);
    setCopied(true, message);
    setTimeout(() => {
      setShowCheck(false);
      setCopied(false, "");
    }, 2000);
  };

  return (
    <button onClick={handleCopy} className={styles.copyText} type="button">
      {children}
    </button>
  );
};
